<template>
<div id="container">
    <router-view />
</div>
</template>
<style>
body {
  padding: 0;
  margin: 0 auto;
  background: #f5f5f5;
  padding-bottom: 31px;
  margin-bottom: 135px;
  /* background-image: url("./assets/logo_bottom.png"); */
  background-repeat: no-repeat;
  background-size: 53% auto;
  background-position: center calc(100% - 95px);
}
p {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: #333;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
/* public css */
.actives-statu {
    position: absolute;
    display: inline-block;
    top: 6px;
    right: 6px;
    color: #666;
    border: 1px solid #cfcfcf;
    border-radius: 6px;
    line-height: 15px;
    padding: 0 3px;
    font-size: 10px;
    z-index: 5;
    transform: rotateZ(-15deg);
    background-color: rgba(255,255,255,0.75);
    box-shadow: 0 0 3px #ccc;

}
.actives-statu.on{
    color: #fff;
    background-color: #F9790B;
    border-color: #F9790B
}
.more-data {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #999;
  padding: 12px 0;
  width:100%;
  background-color: #fcfcfc;
}
.no-data {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #999;
  padding: 12px 0;
}
.specil-tag {
  z-index: 4;
  position: absolute;
  top: -3px;
  right: -6px;
  color: #fff;
  font-size: 9px;
  display: block;
  text-align: center;
  height: 15px;
  line-height: 15px;
  background-color: tomato;
  width: 25px;
  border-radius: 8px;
}
.specil-tag:after {
  z-index: 3;
  content: "";
  display: block;
  position: absolute;
  left: 1px;
  bottom: -1px;
  background-color: tomato;
  height: 6px;
  width: 6px;
  transform: rotateZ(75deg);
}
.check-more {
  text-align: center;
  display: block;
  position: relative;
  padding-right: 9px;
}
.check-more::after,
.check-more::before {
  content: "";
  height: 5px;
  width: 5px;
  position: absolute;
  top: 4px;
  border: 1px solid transparent;
  border-right-color: #999;
  border-bottom-color: #999;
  transform: rotateZ(-45deg);
}
.check-more::before {
  right: 0;
}
.check-more::after {
  right: 4px;
}
</style>
